import { ValidationError, array, boolean, number, object, string } from 'yup'
import { FrequencyTimeUnit, RecencyTimeUnitType } from '@lasso/api-activation/activation'
import { objValues } from '@lasso/shared/utils'
import { extendSchema, oneOfEnum, requiredWhenNotEmpty } from '@lasso/shared/validation'

export const buildCardFrequencySchema = ({ isCardEnabled }: {
  isCardEnabled: boolean
}): typeof baseSchema => {
  const frequencyCapBaseSchema = {
    id: number().default(0),
    localId: string().default(''),
    value: number().nullable().default(null),
    timeUnit: oneOfEnum([...objValues(FrequencyTimeUnit), null]).default(null),
    advancedManagement: boolean().default(false),
  }

  const frequencyBaseSchema = {
    frequencyCaps: array(object(frequencyCapBaseSchema).required()).default([]),
  }

  const recencyBaseSchema = {
    recencyValue: number().nullable().default(null),
    recencyTimeUnit: oneOfEnum([...objValues(RecencyTimeUnitType), null]).default(null),
  }

  const baseSchema = {
    frequency: object(frequencyBaseSchema).required(),
    recency: object(recencyBaseSchema).required(),
  }

  if (!isCardEnabled) {
    return baseSchema
  }

  return {
    frequency: extendSchema(frequencyBaseSchema, {
      frequencyCaps: () => array(
        extendSchema(frequencyCapBaseSchema, {
          value: schema => schema.min(1),
        })
          .required()
          .test(
            'requiredWhenNotEmpty',
            (value, context) => requiredWhenNotEmpty(value, ['timeUnit', 'value'], context.path),
          ),
      )
        .default([])
        .test(
          'timeFramesNotRepeated',
          (value, context) => {
            const timeFrames = value.reduce<Map<FrequencyTimeUnit, number[]>>((timeFrames, cap, index) => {
              if (cap.timeUnit) {
                const indices = timeFrames.get(cap.timeUnit) ?? []
                indices.push(index)
                timeFrames.set(cap.timeUnit, indices)
              }

              return timeFrames
            }, new Map())

            const errors = Array.from(timeFrames.values())
              .filter(indices => indices.length > 1)
              .flatMap((indices) => {
                return indices.map(index => context.createError({
                  path: `${context.path}[${index}].timeUnit`,
                  message: 'Cannot have duplicate timeframe values',
                }))
              })

            return errors.length > 0 ? new ValidationError(errors) : true
          },
        ),
    }).required(),
    recency: extendSchema(recencyBaseSchema, {
      recencyValue: schema => schema.min(1),
    })
      .required()
      .test(
        'requiredWhenNotEmpty',
        (value, context) => requiredWhenNotEmpty(value, ['recencyValue', 'recencyTimeUnit'], context.path),
      ),
  }
}
